import { Component } from '@angular/core';

@Component({
  selector: 'app-webalbum',
  templateUrl: './webalbum.component.html',
  styleUrls: ['./webalbum.component.css']
})
export class WebalbumComponent {

}

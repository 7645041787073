import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from '../components/home/home.component';
import { BestuurComponent } from '../components/bestuur/bestuur.component';
import { PloegenComponent } from '../components/ploegen/ploegen.component';
import { CompetitieComponent } from '../components/competitie/competitie.component';
import { ActiviteitenComponent } from '../components/activiteiten/activiteiten.component';
import { TornooienComponent } from '../components/tornooien/tornooien.component';
import { WebalbumComponent } from '../components/webalbum/webalbum.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'bestuur', component: BestuurComponent },
  { path: 'ploegen', component: PloegenComponent },
  { path: 'competitie', component: CompetitieComponent },
  { path: 'activiteiten', component: ActiviteitenComponent },
  { path: 'tornooien', component: TornooienComponent },
  { path: 'webalbum', component: WebalbumComponent },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '**', redirectTo: 'home', pathMatch: 'full' }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { 


}

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './routing/app-routing.module';
import { HomeComponent } from './components/home/home.component';
import { NavComponent } from './components/nav/nav.component';
import { AppComponent } from './app.component';
import { BestuurComponent } from './components/bestuur/bestuur.component';
import { PloegenComponent } from './components/ploegen/ploegen.component';
import { CompetitieComponent } from './components/competitie/competitie.component';
import { ActiviteitenComponent } from './components/activiteiten/activiteiten.component';
import { TornooienComponent } from './components/tornooien/tornooien.component';
import { WebalbumComponent } from './components/webalbum/webalbum.component';
import { BestuurDetailComponent } from './components/bestuur-detail/bestuur-detail.component';
import { NvtPipe } from './pipes/nvt.pipe';
import { HomeDetailComponent } from './components/home-detail/home-detail.component';
import { DatePipe } from './pipes/date.pipe';
import { TornooiDetailComponent } from './components/tornooi-detail/tornooi-detail.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavComponent,
    BestuurComponent,
    PloegenComponent,
    CompetitieComponent,
    ActiviteitenComponent,
    TornooienComponent,
    WebalbumComponent,
    BestuurComponent,
    BestuurDetailComponent,
    NvtPipe,
    HomeDetailComponent,
    DatePipe,
    TornooiDetailComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

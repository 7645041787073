import { Component, OnInit } from '@angular/core';
import { Article } from 'src/app/models/article';
import { Tournament } from 'src/app/models/tournament';

@Component({
  selector: 'app-tornooien',
  templateUrl: './tornooien.component.html',
  styleUrls: ['./tornooien.component.css']
})
export class TornooienComponent implements OnInit {
  tournaments: Tournament[] = [
    new Tournament(0,"ZOMERDOUBLETTE", "../../assets/tor1.jpg"),
    new Tournament(1,"12 UUR VAN LIER", "../../assets/tor2.jpg")
  ];

  constructor(){
    
  }

  ngOnInit(): void {
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { BoardMember } from 'src/app/models/board-member';

@Component({
  selector: 'app-bestuur-detail',
  templateUrl: './bestuur-detail.component.html',
  styleUrls: ['./bestuur-detail.component.css']
})
export class BestuurDetailComponent implements OnInit {
  @Input() boardMember!: BoardMember;

  ngOnInit(): void{
    
  }
  
}

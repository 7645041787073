import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'date'
})
export class DatePipe implements PipeTransform {

  transform(value: Date|null, ...args: unknown[]): unknown {
    if(value !== null){
      return value.getDay() + "/" + value.getMonth() + "/" + value.getFullYear() + " " + value.getHours() + ":" + value.getMinutes();
    }
    return;
  }

}

import { Component } from '@angular/core';

@Component({
  selector: 'app-competitie',
  templateUrl: './competitie.component.html',
  styleUrls: ['./competitie.component.css']
})
export class CompetitieComponent {

}

export class Address {
    postalCode: number;
    city: string;
    street: string;
    number: number;
    extra: string|null;

    constructor(postalCode: number, city: string, street: string, number: number, extra: string|null){
        this.postalCode = postalCode;
        this.city = city;
        this.street = street;
        this.number = number;
        this.extra = extra;
    }
}
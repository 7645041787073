<div class="col">
  <div class="card h-100 w-20-rem">
    <div class="card-img-top text-center bg-y3">
      <img [src]="boardMember.image" class="img-thumbnail img-height" alt="foto van bestuurslid">
    </div>
    <div class="card-body bg-y1">
      <h4 class="card-title text-center mb-4">{{boardMember.title}}</h4>
      <div>
        <h5 class="fw-bolder">Contact:</h5>
        <label class="fw-bolder">Naam:</label>
        <div>{{boardMember.name}}</div>
      </div>
      <div>
        <label class="fw-bolder">GSM:</label>
        <div>{{boardMember.mobile | nvt}}</div>
      </div>
      <div>
        <label class="fw-bolder">Email:</label>
        <div>{{boardMember.email}}</div>
      </div>
      <div class="mt-3">
        <h5 class="fw-bolder">Address:</h5>
        <label class="fw-bolder">Postcode:</label>
        <div>{{boardMember.address.postalCode}}</div>
      </div>
      <div>
        <label class="fw-bolder">Stad of Gemeente:</label>
        <div>{{boardMember.address.city}}</div>
      </div>
      <div>
        <label class="fw-bolder">Straat:</label>
        <div>{{boardMember.address.street}}</div>
      </div>
      <div>
        <label class="fw-bolder">Nummer:</label>
        <div>
          <span>{{boardMember.address.number}}</span>
          <span *ngIf="boardMember.address.extra != null"> {{boardMember.address.extra}}</span>
        </div>
      </div>
    </div>
  </div>
</div>
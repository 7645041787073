<h1 class="m-4 text-center">Home</h1>
<div class="row row-cols-2 g-5 p-5 pt-0">
    <div class="col-6 ">
        <div class="row g-5">
            <app-home-detail *ngFor="let article of articlesLeft" [article]="article"></app-home-detail>
        </div>
    </div>
    <div class="col-6">
        <div class="row g-5">
            <app-home-detail *ngFor="let article of articlesRight" [article]="article"></app-home-detail>
        </div>
    </div>
</div>
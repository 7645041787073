import { Component, OnInit } from '@angular/core';
import { Address } from 'src/app/models/address';
import { BoardMember } from 'src/app/models/board-member';

@Component({
  selector: 'app-bestuur',
  templateUrl: './bestuur.component.html',
  styleUrls: ['./bestuur.component.css']
})
export class BestuurComponent implements OnInit{
  boardMembers: BoardMember[] = [
    new BoardMember("Sportleider Nationale", "Tim Marien", null, "sportleider@pclier.be", new Address(2500, "Lier", "Begijnhofstraat", 72, null), "../../assets/eddy.png"),
    new BoardMember("Feestleider", "Jos Aerts", "+3247113 66 87", "josaerts56@gmail.com", new Address(2240, "Massenhoven", "Vogelzangstraat", 31, null), "../../assets/tim.jpg"),
    new BoardMember("Chaletverantwoordelijke", "Jan Hendrickx", "+3249463 28 52", "jan.hendrickx@telenet.be", new Address(2240, "Lier", "Hagenbroeksesteenweg", 28, null), "../../assets/jan.jpg"),
    new BoardMember("Feestcomite en Toogdienst", "Mia Marien", "+3249717 90 75", "mia.marien56@proximus.be", new Address(2240, "Lier", "Kazernedreef", 5, "bus 2"), "../../assets/mia.jpg"),
  ]
  constructor(){
    
  }

  ngOnInit(): void {
  }
}

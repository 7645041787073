import { Component, OnInit, Input } from '@angular/core';
import { Article } from 'src/app/models/article';
import { Tournament } from 'src/app/models/tournament';

@Component({
  selector: 'app-tornooi-detail',
  templateUrl: './tornooi-detail.component.html',
  styleUrls: ['./tornooi-detail.component.css']
})
export class TornooiDetailComponent implements OnInit{
  @Input() tournament!: Tournament;

  ngOnInit(): void{
    
  }
}

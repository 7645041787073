import { Address } from "./address";

export class BoardMember {
    title: string;
    name: string;
    mobile: string|null;
    email: string;
    address: Address;
    image: string|null;

    constructor(title: string, name: string, mobile: string|null, email: string, address: Address, image: string|null){
        this.title = title;
        this.name = name;
        this.mobile = mobile;
        this.email = email;
        this.address = address;
        this.image = image;
    }
}

export class Article {
    id: number;
    title: string;
    content: string;
    image: string|null;
    postTimestamp: Date|null;
    updateTimestamp: Date|null;

    constructor(id: number, title: string, content: string, image: string|null, postTimestamp : Date|null, updateTimeestamp : Date|null = null){
        this.id = id;
        this.title = title;
        this.content = content;
        this.image = image;
        this.postTimestamp = postTimestamp;
        this.updateTimestamp = updateTimeestamp;
    }
}
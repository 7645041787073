<div class="card">
    <h5 class="card-header">{{article.title}}</h5>
    <div class="card-body">
      <!-- <h5 class="card-title">{{article.title}}</h5> -->
      <p class="card-text">{{article.content}}</p>
    </div>
    <div class="card-footer text-end">
        <small *ngIf="article.updateTimestamp === null" class="text-muted"><b>Posted: </b>{{article.postTimestamp | date}}</small>
        <small *ngIf="article.updateTimestamp !== null" class="text-muted"><b>Updated: </b>{{article.updateTimestamp | date}}</small>
      </div>
  </div>
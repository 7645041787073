import { Component, OnInit } from '@angular/core';
import { Article } from 'src/app/models/article';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  articlesLeft: Article[] = [
    new Article(0,"Links", "Aan de afrit Mechelen richting MECHELPOORT (Centrum)  helen richting MECHELPOORT (Centrum) nemen(niet de tunnel inrijden) We rijden nu op de Mechelsesteenweg. Hier nemen we de tweede straat links na ong. 500m.(dit is de Pieter Breughellaan) Op het einde van de straat rechtsaf. Na tweehonderd meter kom je in de David Tenierslaan rechtover huisnummer 17 bevinden er zich garages, je gaat tussen de garages door tot aan het poortje, achter het poortje is de ingang van ons lokaal helen richting MECHELPOORT (Centrum) nemen(niet de tunnel inrijden) We rijden nu op de Mechelsesteenweg. Hier nemen we de tweede straat links na ong. 500m.(dit is de Pieter Breughellaan) Op het einde van de straat rechtsaf. Na tweehonderd meter kom je in de David Tenierslaan rechtover huisnummer 17 bevinden er zich garages, je gaat tussen de garages door tot aan het poortje, achter het poortje is de ingang van ons lokaalnemen(niet de tunnel inrijden) We rijden nu op de Mechelsesteenweg. Hier nemen we de tweede straat links na ong. 500m.(dit is de Pieter Breughellaan) Op het einde van de straat rechtsaf. Na tweehonderd meter kom je in de David Tenierslaan rechtover huisnummer 17 bevinden er zich garages, je gaat tussen de garages door tot aan het poortje, achter het poortje is de ingang van ons lokaal.", null, new Date(), null),
    new Article(0,"Links", "Aan de afrit Mechelen richting MECHELPOORT (Centrum) nemen(niet de tunnel inrijden) We rijden nu op de Mechelsesteenweg. Hier nemen we de tweede straat links na ong. 500m.(dit is de Pieter Breughellaan) Op het einde van de straat rechtsaf. Na tweehonderd meter kom je in de David Tenierslaan rechtover huisnummer 17 bevinden er zich garages, je gaat tussen de garages door tot aan het poortje, achter het poortje is de ingang van ons lokaal.", null, new Date(), null)
  ]
  articlesRight: Article[] = [
    new Article(0,"Links", "Aan de afrit Mechelen richting MECHELPOORT (Centrum) nemen(niet de tunnel inrijden) We rijden nu op de Mechelsesteenweg. Hier nemen we de tweede straat links na ong. 500m.(dit is de Pieter Breughellaan) Op het einde van de straat rechtsaf. Na tweehonderd meter kom je in de David Tenierslaan rechtover huisnummer 17 bevinden er zich garages, je gaat tussen de garages door tot aan het poortje, achter het poortje is de ingang van ons lokaal.", "../../assets/pfv.png", new Date(), null),
    new Article(0,"Links", "Aan de afrit Mechelen richting MECHELPOORT (Centrum) nemen(niet de tunnel inrijden) We rijden nu op de Mechelsesteenweg. Hier nemen we de tweede straat links na ong. 500m.(dit is de Pieter Breughellaan) Op het einde van de straat rechtsaf. Na tweehonderd meter kom je in de David Tenierslaan rechtover huisnummer 17 bevinden er zich garages, je gaat tussen de garages door tot aan het poortje, achter het poortje is de ingang van ons lokaal.", null, new Date(), null)
  ]

  ngOnInit(): void{

  }
}

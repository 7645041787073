import { Component } from '@angular/core';

@Component({
  selector: 'app-activiteiten',
  templateUrl: './activiteiten.component.html',
  styleUrls: ['./activiteiten.component.css']
})
export class ActiviteitenComponent {

}

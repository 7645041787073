import { Component } from '@angular/core';

@Component({
  selector: 'app-ploegen',
  templateUrl: './ploegen.component.html',
  styleUrls: ['./ploegen.component.css']
})
export class PloegenComponent {

}
